import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { BoxesSection } from "../components/Sections"
import { BasicContentBox } from "../components/ContentBoxes"
import { Typography, Box } from "@material-ui/core"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"

import ContactForm from "../components/ContactForm"
import { LinkedIn, Twitter } from "../components/Icons"
import SocialIconWrapper from "../components/SocialIconWrapper"

import { KNOX_PHONE_NUMBER, KNOX_CONTACT_EMAIL } from "../content"

const useStyles = makeStyles(theme => {
  return createStyles({
    h1: {
      maxWidth: "500px",
      marginBottom: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: `0 ${theme.spacing(6)}px`,
      },
      [theme.breakpoints.down("xs")]: {
        padding: `0 ${theme.spacing(4)}px`,
      },
      "& h1": {
        [theme.breakpoints.down("sm")]: {
          marginBottom: theme.spacing(1),
        },
      },
    },
    contactInfoContainer: {
      display: "flex",
      color: theme.palette.primary.main,
      alignItems: "flex-start",
      marginLeft: "5px",
      zIndex: theme.zIndex.snackbar,
      [theme.breakpoints.up("md")]: {
        marginLeft: 0,
        display: "block",
        position: "absolute",
        top: `${500 + theme.spacing(9)}px`,
      },
      "& p": {
        marginBottom: 0,
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        fontWeight: 500,
        [theme.breakpoints.up("md")]: {
          marginTop: theme.spacing(1),
          marginLeft: 0,
        },
      },
    },
    link: {
      transition: ".25s",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
  })
})

const ContactPageContent = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <>
      <Box classes={{ root: classes.h1 }}>
        <Typography variant="h1">Contact</Typography>
        <Box position="relative">
          <Box classes={{ root: classes.contactInfoContainer }}>
            <Box display="flex" alignItems="flex-end">
              <Box width="24px" mr={2}>
                <SocialIconWrapper type="LINKED_IN">
                  <LinkedIn fill={theme.palette.primary.main} />
                </SocialIconWrapper>
              </Box>
              <Box width="26px">
                <SocialIconWrapper type="TWITTER">
                  <Twitter fill={theme.palette.primary.main} />
                </SocialIconWrapper>
              </Box>
            </Box>
            <Typography variant="body1">
              <a href={`mailto:${KNOX_CONTACT_EMAIL}`} className={classes.link}>
                {KNOX_CONTACT_EMAIL}
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>

      <BoxesSection
        boxes={[
          {
            content: (
              <BasicContentBox header="Get in Touch or Schedule a Demo">
                <>
                  <Typography>
                    If you want to connect with us, let us know how we can help
                    and we’ll get back to you shortly. Please give us a call if
                    you would like to speak with someone from our team.
                  </Typography>
                </>
                <ContactForm />
              </BasicContentBox>
            ),
            align: "right",
            margin: { xs: { top: "100px" } },
            maxWidth: "577px",
          },
        ]}
        image={{ name: "Auvents1", maxHeight: "500px" }}
      />
    </>
  )
}

const ContactPage = () => (
  <Layout>
    <SEO title="Schedule a Contact" />
    <ContactPageContent />
  </Layout>
)

export default ContactPage
