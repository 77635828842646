import React, { useState } from "react"
import { Formik, Form } from "formik"
import { ArrowRight } from "react-feather"
import { Typography, Box, Button } from "@material-ui/core"
import { TextInput, PhoneInput } from "../Inputs"
import { CheckCircle } from "react-feather"

import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    input: {
      padding: `0 ${theme.spacing(1)}px`,
    },
    formContainer: {
      margin: `0 -${theme.spacing(1)}px`,
    },
  })
})

const ContactForm = () => {
  const [isSent, setIsSent] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Box classes={{ root: classes.formContainer }}>
      <Formik
        initialValues={{ name: "", email: "", phone: "", desc: "" }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          setTimeout(() => {
            fetch(
              "https://y07hzt5zci.execute-api.us-east-1.amazonaws.com/initial/contact",
              {
                method: "POST",
                body: JSON.stringify(values),
              }
            )
            setSubmitting(false)
            setIsSent(true)
          }, 200)
        }}
      >
        {({ isValid }) => {
          return (
            <Form>
              {!isSent ? (
                <>
                  <TextInput
                    classes={{ root: classes.input }}
                    name="name"
                    label="Your Name"
                  />
                  <TextInput
                    classes={{ root: classes.input }}
                    type="email"
                    name="email"
                    label="Email"
                  />
                  <PhoneInput
                    classes={{ root: classes.input }}
                    name="phone"
                    label="Phone"
                  />
                  <TextInput
                    classes={{ root: classes.input }}
                    fullWidth
                    multiline
                    rows={6}
                    rowsMax={8}
                    name="desc"
                    placeholder="Your message"
                  />
                  <Box display="flex" justifyContent="flex-end" paddingX={1}>
                    <Button
                      color="primary"
                      variant="outlined"
                      type="submit"
                      disabled={!isValid}
                      endIcon={<ArrowRight size={12} />}
                    >
                      Send
                    </Button>
                  </Box>
                </>
              ) : (
                <Box mt={4}>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.primary.dark }}
                  >
                    <CheckCircle size={16} /> Your message has been sent
                  </Typography>
                  <Typography variant="body1">
                    Thank you, we'll be in touch very soon.
                  </Typography>
                </Box>
              )}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default ContactForm
